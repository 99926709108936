import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";

interface ColorFieldProps {
  source: string;
}

export const ColorField = ({ source }: ColorFieldProps) => {
  const record = useRecordContext();
  const value = record?.[source];

  const sx = {
    container: {
      width: "100px",
      height: "100px",
      background: record?.color,
    },
  };

  if (!value) {
    return <Box />;
  }

  return (
    <>
      <Box sx={sx.container} />
      {record?.color && (
        <Typography variant="body2">{record?.color}</Typography>
      )}
    </>
  );
};
