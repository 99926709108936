import { Link } from "react-admin";
import { Typography } from "@mui/material";

import { User } from "../types";

interface UserNameFieldProps {
  label?: string;
  user?: User;
}

export const UserNameField = ({ label, user }: UserNameFieldProps) => {
  if (user?.id) {
    return (
      <Link to={`/users/${user?.id}/show`}>
        <Typography variant="body2" component="span">{`${user?.first_name || ""
          } ${user?.last_name || ""}`}</Typography>
      </Link>
    );
  }

  return (
    <Typography variant="body2" component="span">{`${user?.first_name || ""} ${user?.last_name || ""
      }`}</Typography>
  );
};
