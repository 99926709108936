import { AuthProvider } from 'react-admin';

import { sendLogin } from '../api/authentication';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const { data } = await sendLogin({ email: username, password });
    const { token, role, email, id } = data?.data || {};
    if (role !== 'admin') {
      throw new Error("Not authorized.");
    }
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('email', email);
    localStorage.setItem('id', id);
  },
  checkError: async error => {
    const {status} = error?.response || {};
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: async () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  ,
  logout: async () => {
    localStorage.removeItem('token');
  },
  getIdentity: () => Promise.resolve({
    email: localStorage.getItem('email'),
    id: localStorage.getItem('id') || ""
  }),
  getPermissions: () => Promise.resolve(/* ... */),
}

export default authProvider;
