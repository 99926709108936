import { defaultTheme } from "react-admin";

const colors = {
  off_black: "#111111",
  rawSienna: {
    DEFAULT: "#CB6A43",
    "50": "#F3DCD3",
    "100": "#EECFC3",
    "200": "#E6B6A3",
    "300": "#DD9D83",
    "400": "#D48363",
    "500": "#CB6A43",
    "600": "#A8512E",
    "700": "#7C3C22",
    "800": "#502716",
    "900": "#24110A",
  },
};

const theme = {
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: ".15px",
    },
    body2: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: ".15px",
    },
    caption: {
      textTransform: "uppercase" as const,
    },
  },
  components: {
    ...defaultTheme.components,
    MuiToolbar: {
      styleOverrides: {
        root: {
          marginBottom: "10px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.rawSienna.DEFAULT,
          color: "white",
          padding: "7px",
          "&:hover": {
            backgroundColor: colors.rawSienna["300"],
          },
          "&.ra-delete-button": {
            color: "white",
          },
          "&.RaNotification-undo": {
            color: "white",
          },
          "&.MuiButton-outlined": {
            backgroundColor: "white",
            borderColor: colors.rawSienna.DEFAULT,
            color: colors.rawSienna.DEFAULT,
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        PaperProps: {
          elevation: 3,
        },
      },
      styleOverrides: {
        root: {
          zIndex: 100,
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-contentWithSidebar": {
            maxWidth: "100vw",
          },
          "& .RaLayout-content": {
            paddingLeft: "25px",
            background: "white",
            overflow: "scroll",
            width: "100%",
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          "& .RaList-content": {
            boxShadow: "none",
          },
        },
      },
    },
    RaEdit: {
      styleOverrides: {
        root: {
          "& .RaEdit-card": {
            boxShadow: "none",
          },
        },
      },
    },
    RaSearchInput: {
      styleOverrides: {
        root: {
          width: "25em",
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.off_black,
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          height: "inherit",
          "& .RaSidebar-fixed": {
            position: "relative",
          },
        },
      },
    },
    RaBulkDeleteWithUndoButton: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    RaEmpty: {
      styleOverrides: {
        root: {
          "& .RaEmpty-icon": {
            marginTop: "100px",
            width: "32px",
            height: "32px",
          },
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: colors.rawSienna["100"],
          },
          "&.RaMenuItemLink-active": {
            background: colors.rawSienna["400"],
            color: "white",
            "& .RaMenuItemLink-icon": {
              color: "white",
            },
          },
        },
      },
    },
  },
};

export default theme;
