import React from "react";
import { CreateBase } from "react-admin";

import { TitleBar } from "./TitleBar";

interface CreateProps {
  children: React.ReactElement;
  headerSource?: string;
  renderHeader?: (record: any) => React.ReactElement;
  hideShowButton?: boolean;
}

export const Create = ({
  children,
  headerSource,
  renderHeader,
  hideShowButton,
}: CreateProps) => (
  <CreateBase>
    <>
      <TitleBar
        headerSource={headerSource}
        renderHeader={renderHeader}
        hideShowButton={hideShowButton}
        hideEditButton
      />
      {children}
    </>
  </CreateBase>
);
