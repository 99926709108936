import { useState } from "react";
import {
  TextInput,
  SimpleForm,
  Datagrid,
  List,
  TextField,
  useCreate,
  useCreatePath,
  useNotify,
  DateField,
  SearchInput,
} from "react-admin";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { Notification } from "../types/notification";
import { Header } from "../components/Header";
import { Create } from "../components/Create";

const filters = [<SearchInput source="keywords" alwaysOn />];

export const NotificationsList = () => (
  <List filters={filters} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <DateField source="created_at" />
      <TextField source="message" />
    </Datagrid>
  </List>
);

export const NotificationForm = ({
  title,
  method,
}: {
  title?: string;
  method: "create" | "update";
}) => {
  const notify = useNotify();
  const createPath = useCreatePath();

  const [create] = useCreate(undefined, undefined, {
    onSuccess: () => {
      window.location.href = createPath({
        resource: "notifications",
        type: "list",
      });
    },
    onError: (error: { message: string }) => {
      notify(error.message, { type: "warning" });
    },
  });

  const [alertOpen, setAlertOpen] = useState(false);
  const [formData, setFormData] = useState<Notification>();
  const handleNotificationsSave = (data: Notification) => {
    if (data?.message) {
      setFormData(data);
      setAlertOpen(true);
    }
  };

  const sendNotification = async () => {
    if (formData?.message) {
      if (method === "create") {
        setAlertOpen(false);
        create("notifications", {
          data: { message: formData?.message },
        });
      }
    }
  };

  return (
    <SimpleForm onSubmit={handleNotificationsSave} sanitizeEmptyValues>
      <Container maxWidth="md">
        <Header label={title} />
        <TextInput source="message" multiline fullWidth rows={3} />
      </Container>
      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <DialogTitle>Send notification</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will send a push notification to all users with
            permissions enabled.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to do this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setAlertOpen(false)}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={() => sendNotification()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </SimpleForm>
  );
};

export const NotificationCreate = () => (
  <Create>
    <NotificationForm title="Send notification to all users" method="create" />
  </Create>
);
