import { RAImageParam } from '../types';

export const convertFileToBase64 = (file: RAImageParam): Promise<string | ArrayBuffer | null> =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;

  reader.readAsDataURL(file.rawFile);
});


