import { useState } from "react";
import { useParams } from "react-router";
import { Container, Stack, Typography, Grid } from "@mui/material";
import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  SelectInput,
  RecordContextProvider,
  SimpleForm,
  useGetOne,
  TextInput,
  useNotify,
  useDataProvider,
  useUpdate,
  WithRecord,
  DateField,
} from "react-admin";
import { Header } from "../components/Header";
import { SaveAndNotifyToolbar } from "../components/SaveAndNotifyToolbar";

const filters = [
  <SearchInput source="keywords" alwaysOn />,
  <SelectInput
    source="waitlist_status"
    label="Status"
    choices={[
      { id: "approved", name: "Approved" },
      { id: "pending", name: "Pending" },
    ]}
    alwaysOn
  />,
];

export const WaitlistDatagrid = () => (
  <Datagrid
    bulkActionButtons={false}
    rowClick={(id) => `${id}/assign-invite-code`}
  >
    <TextField source="id" label="ID" />
    <TextField source="first_name" label="First Name" />
    <TextField source="last_name" label="Last Name" />
    <TextField source="email" />
    <TextField source="status" />
    <DateField source="created_at" />
    <TextField source="access_code" label="Invite Code / Username" />
    <TextField source="instagram_username" label="Instagram" />
    <TextField source="tiktok_username" label="TikTok" />
  </Datagrid>
);

export const WaitlistItemList = () => (
  <List
    sort={{ field: "created_at", order: "DESC" }}
    resource="waitlist"
    filters={filters}
  >
    <WaitlistDatagrid />
  </List>
);

export const AssignInviteCodeForm = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetOne("waitlist", { id });
  const dataProvider = useDataProvider();

  const notify = useNotify();
  const [update] = useUpdate();
  const handleSubmit = async (values: any) => {
    const { access_code, status } = values;
    return update("waitlist", {
      id,
      data: {
        status,
        access_code: access_code?.toUpperCase(),
      },
    });
  };

  const [notifyIsLoading, setNotifyIsLoading] = useState(false);
  const handleNotifyUser = async () => {
    setNotifyIsLoading(true);
    await dataProvider.notifyUserOfWaitlist(id);
    setNotifyIsLoading(false);
    notify("Notification sent to user");
  };

  if (error) return <p>Error </p>;
  if (isLoading) return <p>Loading...</p>;

  return (
    <RecordContextProvider value={data}>
      <SimpleForm
        onSubmit={handleSubmit}
        toolbar={
          <SaveAndNotifyToolbar
            disabled={data.status === "pending" || notifyIsLoading}
            notifyUser={handleNotifyUser}
          />
        }
      >
        <Container maxWidth="md">
          <Stack spacing={5}>
            <WithRecord
              render={(record: any) => (
                <Header
                  label={`${record.first_name ?? ""} ${record.last_name ?? ""}`}
                />
              )}
            />
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={4}>
                <Typography>First Name</Typography>
                <TextField source="first_name" />
              </Grid>
              <Grid item xs={4}>
                <Typography>Last Name</Typography>
                <TextField source="last_name" />
              </Grid>
              <Grid item xs={4}>
                <Typography>Email</Typography>
                <TextField source="email" />
              </Grid>
              <Grid item xs={4}>
                <Typography>TikTok</Typography>
                <TextField source="tiktok_username" />
              </Grid>
              <Grid item xs={4}>
                <Typography>Instagram</Typography>
                <TextField source="instagram_username" />
              </Grid>
            </Grid>
            <TextInput
              source="access_code"
              fullWidth
              format={(value) => value && value?.toUpperCase()}
            />
            <SelectInput
              source="status"
              choices={[
                { id: "pending", name: "Pending" },
                { id: "approved", name: "Approved" },
              ]}
            />
          </Stack>
        </Container>
      </SimpleForm>
    </RecordContextProvider>
  );
};
