import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useDataProvider,
  useGetList,
  useGetOne,
  useRecordContext,
} from "react-admin";

import { useParams } from "react-router";
import { DollarField } from "../components/DollarField";
import { Header } from "../components/Header";
import { SaveAndNotifyToolbar } from "../components/SaveAndNotifyToolbar";
import { Show } from "../components/Show";
import { UserNameField } from "../components/UserNameField";
import { useSaveFormAndHandleErrors } from "../hooks/useSaveFormAndHandleErrors";
import { Edit } from "../components/Edit";
import { User } from "../types";

const creditFilters = [
  <SelectInput
    alwaysOn
    source="status"
    label="Status"
    choices={[
      { id: "pending", name: "Pending" },
      { id: "applied", name: "Applied" },
    ]}
  />,
];

const sx = {
  textColumn: {
    whiteSpace: "pre-line",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
};

export const CreditsList = () => (
  <List filters={creditFilters} sort={{ field: "id", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="id" />
      <FunctionField
        label="User"
        render={(r: any) => <UserNameField user={r?.user} />}
      />
      <TextField
        source="requested_at"
        label="Date Requested"
        sortable={false}
      />
      <TextField source="status" sortable={false} />
      <FunctionField
        label="Amount"
        render={(r: any) => <DollarField amount={r?.dollar_amount} />}
      />
      <NumberField source="points" sortable={false} />
      <TextField
        source="notified_at"
        label="User Notified"
        emptyText="-"
        sortable={false}
      />
      <TextField sx={sx.textColumn} source="note" sortable={false} />
    </Datagrid>
  </List>
);

const getTodaysDate = () => {
  const dateObject = new Date();
  return `${dateObject.getMonth() + 1}/${
    dateObject.getDate() + 1
  }/${dateObject.getFullYear()}`;
};

const CreditForm = () => {
  const { id } = useParams();
  const { data, error, isLoading, refetch } = useGetOne("credits", { id });

  const { data: reservations } = useGetList("reservations", {
    filter: { guest_id: data?.user?.id, checkout_after: `${getTodaysDate()}` },
  });

  const saveFormAndHandleErrors = useSaveFormAndHandleErrors("credits");
  const handleCreditSubmit = (credit: any, event: any) => {
    saveFormAndHandleErrors(credit, event, false);
  };

  const dataProvider = useDataProvider();
  const handleNotifyUser = async () => {
    await dataProvider.notifyUserOfCoupon(id);
    refetch();
  };

  if (error) return <p>Error </p>;
  if (isLoading) return <p>Loading...</p>;

  const fullName = `${data?.user?.first_name ?? ""} ${
    data?.user?.last_name ?? ""
  }`;

  return (
    <SimpleForm
      onSubmit={handleCreditSubmit}
      toolbar={
        <SaveAndNotifyToolbar
          disabled={false}
          notifyUser={handleNotifyUser}
          notifiedString={
            data?.notified_at
              ? `${fullName} was notified on ${data?.notified_at}`
              : ""
          }
        />
      }
    >
      <Container maxWidth="md">
        <Stack spacing={5}>
          <Header
            label={`${data?.user?.first_name ?? ""} ${
              data?.user?.last_name ?? ""
            } - $${data?.dollar_amount?.toLocaleString()}`}
          />
          <Stack spacing={5} direction="row">
            <Box>
              <Typography>User</Typography>
              <FunctionField
                render={(credit: any) => <UserNameField user={credit.user} />}
              />
            </Box>
            <Box>
              <Typography>Dollar Amount</Typography>
              <FunctionField
                render={(credit: any) => (
                  <DollarField amount={credit.dollar_amount} />
                )}
              />
            </Box>
            <Box>
              <Typography>Points</Typography>
              <TextField source="points" />
            </Box>
            <Box>
              <Typography>Date Requested</Typography>
              <TextField source="requested_at" />
            </Box>
          </Stack>
          {!!reservations && reservations.length > 0 ? (
            <Stack>
              <Typography variant="body1">{`${data?.user?.first_name} ${data?.user?.last_name}'s Upcoming Reservations`}</Typography>
              <Datagrid
                bulkActionButtons={false}
                data={reservations}
                total={reservations?.length}
                sort={{ field: "id", order: "DESC" }}
              >
                <TextField source="id" label="ID" />
                <DateField source="checkin" emptyText="-" />
                <DateField source="checkout" emptyText="-" />
                <TextField source="property" sortable={false} />
                <TextField source="status" />
                <NumberField source="synxis_reservation_id" />
                <DateField source="created_at" />
              </Datagrid>
            </Stack>
          ) : (
            <Typography>No upcoming reservations found.</Typography>
          )}
          <TextInput source="note" multiline minRows={3} />
        </Stack>
      </Container>
    </SimpleForm>
  );
};

export const CreditEdit = () => (
  <Edit redirect={false}>
    <CreditForm />
  </Edit>
);

export const CreditsShow = () => {
  const { user, dollar_amount } = useRecordContext() || {};
  return (
    <Show hideEditButton>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <UserNameField user={user} label="User" />
            <TextField source="status" />
            <DollarField amount={dollar_amount} label="Dollar Amount" />
            <TextField source="points" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
  );
};
