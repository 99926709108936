import * as React from "react";
import {
  Create,
  required,
  BooleanField,
  BooleanInput,
  List,
  Datagrid,
  DateField,
  TextField,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  SimpleShowLayout,
  NumberField,
  RadioButtonGroupInput,
  ImageField as RAImageField,
  SearchInput,
  useCreate,
  useUpdate,
  useCreatePath,
  useNotify,
  WithRecord,
} from "react-admin";
import { Box, Container, Grid, Typography } from "@mui/material";
import { ImageField } from "../components/ImageField";
import { ColorInput } from "../components/ColorInput";
import { ColorField } from "../components/ColorField";
import { QuickFilter } from "../components/QuickFilter";
import { Header } from "../components/Header";
import { Show } from "../components/Show";
import { convertFileToBase64 } from "../utils/files";
import { Perk } from "../types";

const perksFilters = [
  <SearchInput source="keywords" alwaysOn />,
  <QuickFilter source="active" label="Active only" defaultValue />,
  <RadioButtonGroupInput
    source="perk_type"
    label="Coupon/Daily"
    choices={[
      { id: "coupon", name: "Coupon" },
      { id: "daily", name: "Daily" },
    ]}
  />,
];

export const PerkList = () => (
  <List filters={perksFilters} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="title" />
      <TextField source="perk_type" />
      <TextField source="synxis_coupon_name" />
      <TextField source="synxis_coupon_identifier_code" />
      <NumberField source="value_in_points" />
      <BooleanField source="active" sortable={false} />
      <DateField source="created_at" />
      <TextField source="description" sortable={false} />
      <TextField source="instructions" sortable={false} />
    </Datagrid>
  </List>
);

export const PerkShow = () => (
  <Show headerSource="title">
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <TextField source="title" />
          <TextField source="description" />
          <TextField source="synxis_coupon_name" />
          <TextField source="synxis_coupon_identifier_code" />
          <TextField source="perk_type" />
          <NumberField source="value_in_points" />
          <WithRecord
            render={(record) => (
              <Box>
                <Typography className="RaLabeled-label">
                  Instructions
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ whiteSpace: "pre-line" }}
                >{`${record.instructions}`}</Typography>
              </Box>
            )}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <BooleanField source="active" />
          <DateField source="updated_at" />
          <DateField source="created_at" />
          <RAImageField source="image_url" />
          <ColorField source="color" />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  </Show>
);

type HelperType = Omit<Perk, "image">;

export type PerkServerParam = HelperType & {
  image?: string | ArrayBuffer | null;
};

const PerkForm = ({
  title,
  method,
}: {
  title?: string;
  method: "create" | "update";
}) => {
  const notify = useNotify();
  const createPath = useCreatePath();
  const [create] = useCreate(undefined, undefined, {
    onSuccess: () => {
      window.location.href = createPath({ resource: "perks", type: "list" });
    },
    onError: (error: { message: string }) => {
      notify(error.message, { type: "warning" });
    },
  });
  const [update] = useUpdate(undefined, undefined, {
    onSuccess: () => {
      window.location.href = createPath({ resource: "perks", type: "list" });
    },
    onError: (error: { message: string }) => {
      notify(error.message, { type: "warning" });
    },
  });

  const handlePerkSave = async (data: Perk) => {
    const { image, id } = data;
    const transformedPerk = { ...data } as PerkServerParam;
    if (image && image.rawFile) {
      transformedPerk.image = await convertFileToBase64(image);
    } else {
      transformedPerk.image = undefined;
    }

    if (method === "create") {
      create("perks", { data: { perk: { ...transformedPerk } } });
    } else {
      update("perks", { id, data: { perk: { ...transformedPerk } } });
    }
  };

  return (
    <SimpleForm onSubmit={handlePerkSave} sanitizeEmptyValues>
      <Container maxWidth="md">
        <Header label={title} />
        <TextInput source="id" label="ID" disabled />
        <TextInput source="title" fullWidth />
        <TextInput multiline source="description" fullWidth />
        <NumberInput source="value_in_points" />
        <TextInput
          multiline
          source="instructions"
          label="instructions (multiline)"
          fullWidth
        />
        <RadioButtonGroupInput
          source="perk_type"
          validate={required()}
          choices={[
            { id: "coupon", name: "Coupon" },
            { id: "daily", name: "Daily" },
          ]}
        />
        <TextInput source="synxis_coupon_name" fullWidth />
        <TextInput source="synxis_coupon_identifier_code" fullWidth />
        <ImageField
          resource="perks"
          urlSource="image_url"
          fileSource="image"
          label="Image"
        />
        <BooleanInput source="active" />
        <ColorInput source="color" />
      </Container>
    </SimpleForm>
  );
};

export const PerkEdit = () => (
  <Edit>
    <PerkForm title="Edit perk" method="update" />
  </Edit>
);

export const PerkCreate = () => (
  <Create>
    <PerkForm title="Create new perk" method="create" />
  </Create>
);
