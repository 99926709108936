import {
  useRecordContext,
  Datagrid,
  DateField,
  List,
  SimpleShowLayout,
  TextField,
  Link,
  SearchInput,
  useDataProvider,
  useNotify,
  WithRecord,
  useRefresh,
} from "react-admin";
import { Button, Grid, Typography } from "@mui/material";

import { Show } from "../components/Show";
import { Header } from "../components/Header";
import { QuickFilter } from "../components/QuickFilter";
import { Reward } from "../types";

/* eslint-disable @typescript-eslint/no-unused-vars */
// need to include label because it magically is used by the datagrid
const PerkField = ({ label }: { label: string }) => {
  const reward = useRecordContext();
  const perkShowPage = `/perks/${reward.perk.id}/show`;
  return reward ? (
    <Link to={perkShowPage}>
      <Typography variant="body2" component="span">
        {reward.perk.title}
      </Typography>
    </Link>
  ) : null;
};

const filters = [
  <SearchInput source="keywords" alwaysOn />,
  <QuickFilter source="active" label="Active" defaultValue />,
];

export const RewardList = () => (
  <List filters={filters}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="active" sortable={false} />
      <DateField source="created_at" />
      <TextField source="expires_at" />
      <PerkField label="Perk" />
      <TextField source="synxis_coupon_code" sortable={false} />
      <WithRecord
        label="Refunded"
        render={(reward) => (
          <span>
            {reward.refund && <DateField source="refund.created_at" showTime />}
          </span>
        )}
      />
      <DateField source="updated_at" sortable={false} />
      <TextField source="valid_ends_at" emptyText="-" />
      <TextField source="valid_starts_at" emptyText="-" />
    </Datagrid>
  </List>
);

const RefundButton = ({ reward }: { reward: Reward }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const refund = async () => {
    try {
      if (reward?.id) {
        await dataProvider.refundReward(reward?.id);
        refresh();
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred.";
      notify(message, { type: "error" });
    }
  };
  return <Button onClick={refund}>Refund</Button>;
};

export const RewardShow = () => (
  <Show
    renderHeader={(record) => (
      <Header
        label={`${record?.perk?.title} - ${record?.synxis_coupon_code || ""}`}
      />
    )}
    hideEditButton
  >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <TextField source="active" />
          <PerkField label="Perk" />
          <TextField source="synxis_coupon_code" />
          <WithRecord
            render={(reward) => (
              <div>
                {reward.refund ? (
                  <div>
                    <Typography>Refunded on:</Typography>
                    <DateField source="refund.created_at" showTime />
                  </div>
                ) : (
                  <RefundButton reward={reward} />
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <DateField source="valid_ends_at" />
          <DateField source="valid_starts_at" />
          <DateField source="expires_at" />
          <DateField source="updated_at" />
          <DateField source="created_at" />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  </Show>
);
