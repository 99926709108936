import { Button, Typography } from "@mui/material";
import { SaveButton, Toolbar } from "react-admin";

interface SaveAndNotifyToolbarProps {
  notifyUser: () => Promise<void>;
  disabled: boolean;
  notifiedString?: string;
}

const sx = {
  button: {
    mx: 5,
  },
};

export const SaveAndNotifyToolbar = ({
  notifyUser,
  disabled,
  notifiedString,
  ...props
}: SaveAndNotifyToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button
      onClick={notifyUser}
      disabled={disabled}
      variant="contained"
      sx={sx.button}
    >
      Notify User
    </Button>
    {notifiedString && (
      <Typography variant="body2">{notifiedString}</Typography>
    )}
  </Toolbar>
);
