import React from "react";
import { EditBase } from "react-admin";

import { TitleBar } from "./TitleBar";

interface EditProps {
  children: React.ReactElement;
  headerSource?: string;
  renderHeader?: (record: any) => React.ReactElement;
  hideShowButton?: boolean;
  [key: string]: any;
}

export const Edit = ({
  children,
  headerSource,
  renderHeader,
  hideShowButton,
  ...props
}: EditProps) => (
  <EditBase mutationMode="pessimistic" {...props}>
    <>
      <TitleBar
        headerSource={headerSource}
        renderHeader={renderHeader}
        hideShowButton={hideShowButton}
        hideEditButton
      />
      {children}
    </>
  </EditBase>
);
