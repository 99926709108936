import { DataProvider } from "react-admin";
import { stringify } from "query-string";

import api from "../api";

const apiUrl = `${process.env.REACT_APP_BASE_URL}api/v1/admin`;

const getTodaysDate = () => {
  const dateObject = new Date();
  return `${dateObject.getMonth() + 1}/${dateObject.getDate() + 1
    }/${dateObject.getFullYear()}`;
};

function createFilterEntry(
  query: any,
  resource: string,
  key: string,
  value: any
) {
  const newQuery = { ...query };

  // waitlist
  if (key === "waitlist_status") {
    if (value === "pending") {
      newQuery[`${resource}s.status`] = 0;
    }
    if (value === "approved") {
      newQuery[`${resource}s.status`] = 1;
    }
  }

  // reservations
  if (key === "reservation_type") {
    if (value === "referrals") {
      newQuery[`${resource}.guest_id`] = `not null`;
    }
    if (value === "users") {
      newQuery[`${resource}.referrer_id`] = `null`;
    }
  }

  // credits
  if (key === "status") {
    if (value === "applied") {
      newQuery[`${resource}.notified_at`] = "not null";
    }
    if (value === "pending") {
      newQuery[`${resource}.notified_at`] = "null";
    }
  }

  // users
  if (key === "role") {
    newQuery[`${resource}.role`] = value;
  }

  // reservations
  if (key === "guest_id") {
    newQuery[`${resource}.guest_id`] = value;
  }
  if (key === "checkout_after") {
    newQuery[`${resource}.checkout`] = value;
    newQuery[`${resource}.checkout.rel`] = ">";
  }




  return newQuery;
}

const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    const { keywords, ...filters } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    // waitlist is singular but needs plural sort key
    const sortResource = resource === "waitlist" ? "waitlists" : resource;
    let query: { [key: string]: unknown } = {
      page,
      keywords,
      sort_key: field ? `${sortResource}.${field}` : undefined,
      sort_direction: order,
      per_page: perPage,
    };
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        query = createFilterEntry(query, resource, key, filters[key]);
      }
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const response = await api(url);
    const { data, meta } = response.data || {};

    return {
      data,
      total: meta?.total_count,
    };
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;

    const response = await api(url);
    const { data } = response.data || {};

    return {
      data,
    };
  },

  // TODO: broken
  /* eslint-disable @typescript-eslint/no-unused-vars */
  getMany: (resource, params) => Promise.resolve({ data: [] }),

  // TODO: broken
  getManyReference: (resource, params) => Promise.resolve({ data: [] }),

  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const response = await api.post(url, params.data);
    return response?.data;
  },

  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const response = await api.put(url, params.data);
    return response?.data;
  },

  // TODO: broken
  updateMany: (resource, params) => Promise.resolve({ data: [] }),

  delete: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    return api.delete(url);
  },

  // TODO: broken
  deleteMany: (resource, params) => {
    const { ids } = params;
    for (const id of ids) {
      dataProvider.delete(resource, { id });
    }
    return Promise.resolve({ data: ids });
  },

  deletePhoto: (resource: string, id: string, photo_prop: string) => {
    const url = `${apiUrl}/${resource}/${id}/delete_${photo_prop}`;
    return api.post(url);
  },

  refundReward: (id: string) => {
    const url = `${apiUrl}/rewards/${id}/refund`;
    return api.post(url);
  },

  notifyUserOfCoupon: (id: string) => {
    const url = `${apiUrl}/credits/${id}/notify`;
    return api.post(url);
  },

  approveUsers: (ids: string[]) => {
    for (const id of ids) {
      const url = `${apiUrl}/waitlist/${id}`;
      api.patch(url, { waitlist: { status: "approved" } });
    }
    return Promise.resolve({ data: ids });
  },

  setUserAccessCode: (id: string, access_code: string) =>
    Promise.resolve({ data: id }),

  notifyUserOfWaitlist: (id: string) => {
    const url = `/api/v1/admin/waitlist/${id}/notify`;
    return api.post(url);
  },

  addPointsToUser: (id: string, points: number, note?: string) => {
    const url = `/api/v1/admin/users/${id}/grant_points`;
    return api.patch(url, { user: { points, note } });
  }
};
export default dataProvider;
