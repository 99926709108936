import React from "react";
import { ShowBase } from "react-admin";

import { TitleBar } from "./TitleBar";

interface ShowProps {
  children: React.ReactElement;
  headerSource?: string;
  renderHeader?: (record: any) => React.ReactElement;
  hideEditButton?: boolean;
  titleBar?: () => React.ReactElement;
}

export const Show = ({
  children,
  headerSource,
  renderHeader,
  hideEditButton,
  titleBar
}: ShowProps) => (
  <ShowBase>
    <>
      <TitleBar
        headerSource={headerSource}
        renderHeader={renderHeader}
        hideEditButton={hideEditButton}
        hideShowButton
      >
        {titleBar?.()}
      </TitleBar>
      {children}
    </>
  </ShowBase>
);
