import * as React from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  SimpleForm,
  SimpleShowLayout,
  RadioButtonGroupInput,
  Create,
  SearchInput,
  WithRecord,
  SelectInput,
  NumberField,
  TextInput,
  Button,
  RecordContextProvider,
  useDataProvider,
  useGetOne,
  SaveButton,
  Toolbar,
} from "react-admin";
import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router";

import { ImageField } from "../components/ImageField";
import { Header } from "../components/Header";
import { Show } from "../components/Show";
import { Edit } from "../components/Edit";
import { convertFileToBase64 } from "../utils/files";
import { User } from "../types";
import { isRAImage } from "../types/images";
import { useSaveFormAndHandleErrors } from "../hooks/useSaveFormAndHandleErrors";
import { UserNameField } from "../components/UserNameField";

const userFilters = [
  <SearchInput source="keywords" alwaysOn />,
  <SelectInput
    alwaysOn
    source="role"
    label="Role"
    choices={[
      { id: "user", name: "User" },
      { id: "admin", name: "Admin" },
    ]}
  />,
];

const sx = {
  datagrid: {
    "& .RaDatagrid-rowCell": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .column-first_name": {
      maxWidth: "10em",
    },
    "& .column-last_name": {
      maxWidth: "10em",
    },
    "& .column-email": {
      maxWidth: "270px",
    },
  },
  avatar: { m: "10px", height: "100px", width: "100px" },
};

export const UserList = () => (
  <List filters={userFilters} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show" sx={sx.datagrid}>
      <TextField source="id" label="ID" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="role" />
      <NumberField source="available_points" sortable={false} />
      <TextField source="email" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <DateField source="deleted_at" />
    </Datagrid>
  </List>
);

export const UserShow = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const handleAddPoints = () => {
    navigate(`/users/${id}/add-points`);
  }

  return (
    <Show headerSource="name" titleBar={() => <Button onClick={handleAddPoints} label="Add Points" startIcon={<AddIcon />} />}>
      <>
        <WithRecord
          render={(record) => {
            if (record?.avatar) {
              return (
                <Avatar sx={sx.avatar}>
                  <img
                    src={record.avatar}
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </Avatar>
              );
            }
            return <Box />;
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SimpleShowLayout>
              <TextField source="name" />
              <TextField source="id" label="ID" />
              <TextField source="username" />
              <TextField source="email" />
              <TextField source="phone_number" />
              <NumberField source="available_points" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={6}>
            <SimpleShowLayout>
              <TextField source="role" />
              <DateField source="created_at" />
              <DateField source="updated_at" />
              <TextField source="tiktok_username" />
              <TextField source="instagram_username" />
              <TextField source="youtube_username" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </>
    </Show >
  );
}

type HelperType = Omit<User, "avatar" | "address">;

export type UserServerParam = HelperType & {
  address?:
  | {
    street?: string;
    address_line_2?: string;
    city?: string;
    zip?: string;
    state?: string;
    country?: string;
  }

  | { _destroy: string | boolean };
  avatar?: string | ArrayBuffer | null;
};

const UserForm = ({ title }: { title?: string }) => {
  const saveFormAndHandleErrors = useSaveFormAndHandleErrors("users");
  const handleUserSave = async (data: User, event: any) => {
    const { avatar } = data;
    const transformedUser = { ...data } as UserServerParam;
    if (avatar && isRAImage(avatar)) {
      transformedUser.avatar = await convertFileToBase64(avatar);
    } else {
      transformedUser.avatar = undefined;
    }

    return saveFormAndHandleErrors({ user: { ...transformedUser } }, event);
  };

  return (
    <SimpleForm onSubmit={handleUserSave} sanitizeEmptyValues>
      <Container maxWidth="md">
        <Header label={title} />
        <ImageField urlSource="avatar" resource="users" fileSource="avatar" />
        <TextInput source="id" label="ID" disabled fullWidth />
        <TextInput source="first_name" fullWidth />
        <TextInput source="last_name" fullWidth />
        <TextInput source="email" fullWidth />
        <TextInput source="username" fullWidth />
        <TextInput source="phone_number" fullWidth />
        <RadioButtonGroupInput
          source="role"
          choices={[
            { id: "user", name: "User" },
            { id: "admin", name: "Admin" },
          ]}
          fullWidth
        />
        <TextInput source="instagram_username" fullWidth />
        <TextInput source="tiktok_username" fullWidth />
        <TextInput source="youtube_username" fullWidth />
      </Container>
    </SimpleForm>
  );
};

export const UserEdit = () => (
  <Edit headerSource="name">
    <UserForm />
  </Edit>
);

export const UserCreate = () => (
  <Create>
    <UserForm title="Create new user" />
  </Create>
);

export const AddPointsForm = () => {
  const { id } = useParams();
  const { data, error, isLoading, refetch } = useGetOne("users", { id });
  const dataProvider = useDataProvider();

  const handleSubmit = async (values: any) => {
    const { points, note } = values;
    await dataProvider.addPointsToUser(id, points, note);
    refetch();
  }

  const fullName = `${data?.first_name ?? ""} ${data?.last_name ?? ""}`;
  return (
    <RecordContextProvider value={data}>
      <SimpleForm
        onSubmit={handleSubmit}
        toolbar={<Toolbar><SaveButton /></Toolbar>}
      >

        <Stack spacing={5}>
          <Header
            label={`Add Points to ${fullName} `}
          />
          <Stack spacing={5} direction="row">
            <Box>
              <Typography>User</Typography>
              <UserNameField user={data} label="User" />
            </Box>
            <Box>
              <Typography>Current Balance</Typography>
              <Typography>{data?.available_points}</Typography>
            </Box>
          </Stack>
          <Box>
            <Typography>Points</Typography>
            <TextInput fullWidth source="points" />
          </Box>
          <Box>
            <Typography>Notes</Typography>
            <TextInput fullWidth source="note" multiline minRows={3} />
          </Box>
        </Stack>
      </SimpleForm >
    </RecordContextProvider >
  );

}
