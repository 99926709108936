import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { Form, required, TextInput, useNotify } from "react-admin";
import Box from "@mui/material/Box";

import { sendForgotPassword } from "../api/authentication";
import { AuthContainer } from "../components/AuthContainer";

const sx = {
  input: {
    mb: 1,
  },
  button: {
    mb: 2,
  },
  subtitle: {
    mb: 2,
  },
};

export const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState(false);

  const notify = useNotify();
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    setLoading(true);

    try {
      sessionStorage.setItem("email", data?.email);
      await sendForgotPassword({ user: { ...data } });
      navigate("/verify-code");
    } catch (error: any) {
      setLoading(false);
      notify(typeof error === "string" ? error : error.message, {
        type: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit}>
        <AuthContainer title="Forgot Password">
          <Typography sx={sx.subtitle}>
            Enter your email to get sent a verification code
          </Typography>
          <Box sx={sx.input}>
            <TextInput
              autoFocus
              source="email"
              label="Email"
              disabled={loading}
              validate={required()}
              fullWidth
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
              sx={sx.button}
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              Request Email
            </Button>
            <Link href="/login">
              <Typography>Back</Typography>
            </Link>
          </Box>
        </AuthContainer>
      </Form>
    </Box>
  );
};
