import { Container } from "@mui/material";
import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  regex,
  required,
  SearchInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { Create } from "../components/Create";
import { Edit } from "../components/Edit";
import { Header } from "../components/Header";
import { Show } from "../components/Show";
import { useSaveFormAndHandleErrors } from "../hooks/useSaveFormAndHandleErrors";
import { Page } from "../types/page";

const pagesFilters = [<SearchInput source="keywords" alwaysOn />] as any[];

const sx = {
  textColumn: {
    whiteSpace: "pre-line",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  textField: {
    whiteSpace: "pre-line",
    textTransform: "none",
  },
};

export const PageList = () => (
  <List
    filters={pagesFilters}
    sort={{ field: "last_updated_at", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="handle" />
      <DateField source="last_updated_at" />
      <TextField sx={sx.textColumn} source="text" sortable={false} />
    </Datagrid>
  </List>
);

export const PageShow = () => (
  <Show headerSource="title">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="handle" />
      <DateField source="last_updated_at" />
      <TextField sx={sx.textField} source="text" />
    </SimpleShowLayout>
  </Show>
);

const PageForm = ({ title }: { title?: string }) => {
  const saveFormAndHandleErrors = useSaveFormAndHandleErrors("pages");

  const handlePageSave = async (data: Page, event: any) => {
    const today = new Date().toISOString().split("T")[0];
    return saveFormAndHandleErrors(
      { page: { ...data, last_updated_at: today } },
      event,
    );
  };

  return (
    <SimpleForm onSubmit={handlePageSave}>
      <Container maxWidth="md">
        <Header label={title} />
        <TextInput source="id" disabled fullWidth />
        <TextInput source="title" validate={required()} />
        <TextInput
          source="handle"
          fullWidth
          validate={[required(), regex(/^\S+$/, "May not have whitespace")]}
        />
        <TextInput multiline source="text" fullWidth validate={required()} />
      </Container>
    </SimpleForm>
  );
};

export const PageEdit = () => (
  <Edit headerSource="title">
    <PageForm />
  </Edit>
);

export const PageCreate = () => (
  <Create>
    <PageForm title="Create new user" />
  </Create>
);
