import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
} from "react-admin";
import Box from "@mui/material/Box";

import { AuthContainer } from "../components/AuthContainer";

const sx = {
  input: {
    mb: 1,
  },
  button: {
    mb: 2,
  },
  subtitle: {
    mb: 2,
  },
};

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth: unknown) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error: Error) => {
        setLoading(false);
        notify(typeof error === "string" ? error : error.message, {
          type: "warning",
        });
      }
    );
  };

  const showResetMessage = location?.search?.indexOf("reset") !== -1;

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <AuthContainer>
        <Box>
          {showResetMessage && (
            <Typography sx={sx.subtitle}>
              Password successfully reset. Please log in.
            </Typography>
          )}
          <Box sx={sx.input}>
            <TextInput
              autoFocus
              source="username"
              label="Email"
              disabled={loading}
              validate={required()}
              fullWidth
            />
          </Box>
          <Box sx={sx.input}>
            <TextInput
              source="password"
              label="Password"
              type="password"
              disabled={loading}
              validate={required()}
              fullWidth
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading}
            fullWidth
            sx={sx.button}
          >
            {loading && <CircularProgress size={25} thickness={2} />}
            {translate("ra.auth.sign_in")}
          </Button>
          <Link href="/forgot-password">
            <Typography>Forgot Password?</Typography>
          </Link>
        </Box>
      </AuthContainer>
    </Form>
  );
};
