import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
  Admin,
  Resource,
  CustomRoutes,
  EditGuesser,
  ShowGuesser,
  ListGuesser,
} from "react-admin";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { UserList, UserEdit, UserShow, UserCreate, AddPointsForm } from "./users";
import { PerkList, PerkEdit, PerkShow, PerkCreate } from "./perks";
import { ReservationList, ReservationShow } from "./reservations";
import { RewardList, RewardShow } from "./rewards";
import {
  PointsWindowCreate,
  PointsWindowEdit,
  PointsWindowShow,
  PointsWindowsList,
} from "./points_windows";
import theme from "../theme";
import { CustomLayout } from "../components/CustomLayout";
import { LoginPage } from "../pages/Login";
import { HotelList, HotelShow, HotelEdit, HotelCreate } from "./hotels";
import { ForgotPasswordPage } from "../pages/ForgotPassword";
import { VerifyCodePage } from "../pages/VerifyCode";
import { ResetPasswordPage } from "../pages/ResetPassword";
import { AssignInviteCodeForm, WaitlistItemList } from "./waitlist";
import { NotificationsList, NotificationCreate } from "./notifications";
import { PageCreate, PageEdit, PageList, PageShow } from "./pages";
import { CreditEdit, CreditsList, CreditsShow } from "./credits";

const App = () => (
  <BrowserRouter>
    <Admin
      layout={CustomLayout}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        show={UserShow}
        create={UserCreate}
      />
      <Resource
        name="perks"
        list={PerkList}
        edit={PerkEdit}
        show={PerkShow}
        create={PerkCreate}
      />
      <Resource
        name="reservations"
        list={ReservationList}
        show={ReservationShow}
      />
      <Resource name="rewards" list={RewardList} show={RewardShow} />
      <Resource
        name="hotels"
        options={{ label: "Properties" }}
        list={HotelList}
        show={HotelShow}
        edit={HotelEdit}
        create={HotelCreate}
      />
      <Resource
        name="notifications"
        list={NotificationsList}
        create={NotificationCreate}
      />
      <Resource
        name="points_windows"
        options={{ label: "Promotions" }}
        list={PointsWindowsList}
        show={PointsWindowShow}
        edit={PointsWindowEdit}
        create={PointsWindowCreate}
      />
      <Resource
        name="credits"
        options={{ label: "Points" }}
        list={CreditsList}
        edit={CreditEdit}
        show={CreditsShow}
      />
      <Resource name="waitlist" list={WaitlistItemList} />
      <Resource
        name="pages"
        list={PageList}
        show={PageShow}
        edit={PageEdit}
        create={PageCreate}
        recordRepresentation={(record) => record.handle}
      />
      <CustomRoutes>
        <Route path="/users/:id/add-points" element={<AddPointsForm />} />
        <Route
          path="/waitlist/:id/assign-invite-code"
          element={<AssignInviteCodeForm />}
        />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/verify-code" element={<VerifyCodePage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </CustomRoutes>
    </Admin>
  </BrowserRouter>
);

export default App;
