import axios from "axios";
import { HttpError } from "react-admin";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  timeout: 200000,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token && config.headers) {
    /* eslint-disable no-param-reassign */
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    /* eslint-disable no-console */
    if (error?.response?.data?.errors) {
      const { message, errors } = error?.response?.data || {};
      const body = {
        errors,
      };
      const e = new HttpError(
        message || "There was an error",
        error?.response?.status || 400,
        body
      );
      return Promise.reject(e);
    }
    if (error?.response?.status === 403) {
      // bad token, log user out
    }
    return Promise.reject(error);
  }
);

export default api;
