import api from "./index";

export interface LoginArgs {
  email: string;
  password: string;
}

export const sendLogin = async ({ email, password }: LoginArgs) =>
  api.post("/api/v1/login", {
    user: {
      email,
      password,
    },
  });

export interface ForgotPasswordArgs {
  user: {
    email: string;
  };
}
export interface ForgotPasswordData {
  message: string;
}
export const sendForgotPassword = async (args: ForgotPasswordArgs) =>
  api.post("/api/v1/forgot_password", args);

export interface VerifyPINArgs {
  user: {
    email: string;
    verification_pin: string;
  };
}

export interface VerifyPINData {
  token: string;
}

export const sendVerifyPIN = async (args: VerifyPINArgs) =>
  api.post<VerifyPINData>("api/v1/verify_pin", args);

export interface ResetPasswordArgs {
  token: string;
  user: {
    password: string;
    password_confirmation: string;
  };
}

export const sendResetPassword = async ({ token, user }: ResetPasswordArgs) =>
  api.post(
    "api/v1/reset_password",
    { user },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export interface ResendPinArgs {
  user: {
    email: string;
  };
}
export interface ResendPinData {
  data: {
    message: string;
  };
}

export const sendResendPin = async (args: ResendPinArgs) =>
  api.post<ResendPinData>("/api/v1/resend_pin", args);
