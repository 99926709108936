import {
  useRecordContext,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Link,
  SearchInput,
  SimpleShowLayout,
  SelectInput,
} from "react-admin";
import { Grid, Typography } from "@mui/material";

import { Show } from "../components/Show";
import { Header } from "../components/Header";

/* eslint-disable @typescript-eslint/no-unused-vars */
// need to include label because it magically is used by the datagrid
const ReferrerField = ({ label }: { label: string }) => {
  const reservation = useRecordContext();
  const userShowPage = reservation?.referrer?.id
    ? `/users/${reservation?.referrer?.id}/show`
    : "";
  return reservation && reservation?.referrer ? (
    <Link to={userShowPage}>
      <Typography variant="body2" component="span">
        {reservation?.referrer?.name}
      </Typography>
    </Link>
  ) : null;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// need to include label because it magically is used by the datagrid
const GuestNameField = ({ label }: { label: string }) => {
  const reservation = useRecordContext();

  return reservation?.guest?.id ? (
    <Link to={`/users/${reservation.guest?.id}/show`}>
      <Typography
        variant="body2"
        component="span"
      >{`${reservation?.guest_first_name} ${reservation?.guest_last_name}`}</Typography>
    </Link>
  ) : (
    <Typography
      variant="body2"
      component="span"
    >{`${reservation?.guest_first_name} ${reservation?.guest_last_name}`}</Typography>
  );
};

const filters = [
  <SearchInput source="keywords" alwaysOn />,
  <SelectInput
    source="reservation_type"
    label="Reservation Type"
    choices={[
      { id: "referrals", name: "Referrals" },
      { id: "users", name: "Users" },
      { id: "both", name: "All" },
    ]}
    alwaysOn
  />,
];

export const ReservationList = () => (
  <List filters={filters} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" label="ID" />
      <DateField source="checkin" emptyText="-" />
      <DateField source="checkout" emptyText="-" />
      <GuestNameField label="Guest" />
      <TextField source="property" sortable={false} />
      <ReferrerField label="Referrer" />
      <TextField source="status" />
      <NumberField source="synxis_reservation_id" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

const formatHeaderDate = (dateString?: string) => {
  if (dateString) {
    return new Date(dateString).toLocaleDateString();
  }
  return "";
};

export const ReservationShow = () => (
  <Show
    hideEditButton
    renderHeader={(record) => (
      <Header
        label={`${record?.guest_first_name} ${
          record?.guest_last_name
        } ${formatHeaderDate(record?.checkin)} - ${formatHeaderDate(
          record?.checkout
        )}`}
      />
    )}
  >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <GuestNameField label="guest" />
          <DateField source="checkin" />
          <DateField source="checkout" />
          <DateField source="created_at" label="created" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField source="property" />
          <ReferrerField label="referrer" />
          <TextField source="status" />
          <TextField source="friendly_status" label="confirmed/pending" />
          <TextField source="synxis_reservation_id" />
          <DateField source="updated_at" />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  </Show>
);
