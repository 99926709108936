import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { EditButton, ShowButton, WithRecord } from "react-admin";
import { Header } from "./Header";

interface TitleBarProps {
  headerSource?: string;
  renderHeader?: (record: any) => React.ReactElement;
  hideShowButton?: boolean;
  hideEditButton?: boolean;
  children?: React.ReactNode;
}

const sx = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    mb: "15px",
  },
  button: {
    alignSelf: "flex-end",
  },
  header: {
    minHeight: "1em",
  },
};

export const TitleBar = ({
  headerSource,
  renderHeader,
  hideShowButton,
  hideEditButton,
  children,
}: TitleBarProps) => (
  <Box sx={sx.container}>
    {headerSource && <Header source={headerSource} />}
    {renderHeader && <WithRecord render={renderHeader} />}
    {!headerSource && !renderHeader && <Header sx={sx.header} label=" " />}
    <Stack direction="row" spacing="5px">
      {!hideShowButton && <ShowButton sx={sx.button} />}
      {!hideEditButton && <EditButton sx={sx.button} />}
      <Box sx={{ display: "inline-block", alignSelf: "flex-end" }}>
        {children}
      </Box>
    </Stack>
  </Box>
);
