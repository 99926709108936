import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { Form, required, TextInput, useNotify } from "react-admin";
import Box from "@mui/material/Box";

import { sendResetPassword } from "../api/authentication";
import { AuthContainer } from "../components/AuthContainer";

const sx = {
  input: {
    mb: 1,
  },
  button: {
    mb: 2,
  },
  subtitle: {
    mb: 2,
  },
};

export const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);

  const notify = useNotify();
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        notify("No token found in session. Verify email again.", {
          type: "error",
        });
        setDisableButton(true);
        return;
      }

      await sendResetPassword({ token, user: data });
      navigate("/login?reset=true");
    } catch (error: any) {
      setLoading(false);
      notify(typeof error === "string" ? error : error.message, {
        type: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      notify(
        "No token found in session. Go back to forgot password page and re-verify email.",
        { type: "error" }
      );
      setDisableButton(true);
    }
  }, [notify]);

  return (
    <Box>
      <Form onSubmit={handleSubmit}>
        <AuthContainer title="Reset Password">
          <Box>
            <Typography sx={sx.subtitle}>Enter new password</Typography>
            <Box sx={sx.input}>
              <TextInput
                type="password"
                autoFocus
                source="password"
                label="New Password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={sx.input}>
              <TextInput
                type="password"
                source="password_confirmation"
                label="Confirm Password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading || disableButton}
              fullWidth
              sx={sx.button}
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              Verify Code
            </Button>
            <Link href="/forgot-password">
              <Typography>Back</Typography>
            </Link>
          </Box>
        </AuthContainer>
      </Form>
    </Box>
  );
};
