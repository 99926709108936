import {
  SimpleForm,
  Datagrid,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
  ImageField as RAImageField,
  SearchInput,
} from "react-admin";
import { Container, Grid, Stack } from "@mui/material";

import { ImageField } from "../components/ImageField";
import { Hotel } from "../types/hotel";
import { Header } from "../components/Header";
import { Show } from "../components/Show";
import { convertFileToBase64 } from "../utils/files";
import { Edit } from "../components/Edit";
import { Create } from "../components/Create";
import { useSaveFormAndHandleErrors } from "../hooks/useSaveFormAndHandleErrors";

const filters = [<SearchInput source="keywords" alwaysOn />];

export const HotelList = () => (
  <List
    title="Properties"
    filters={filters}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="street" />
      <TextField source="state" />
      <TextField source="city" />
      <TextField source="synxis_code" />
    </Datagrid>
  </List>
);

export const HotelShow = () => (
  <Show headerSource="name">
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="phone_number" />
          <TextField source="synxis_code" />
          <TextField source="checkin" />
          <TextField source="checkout" />
          <RAImageField source="image_url" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField source="street" />
          <TextField source="city" />
          <TextField source="state" />
          <TextField source="zip" />
          <TextField source="lat" />
          <TextField source="lng" />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  </Show>
);

type HelperType = Omit<Hotel, "image">;

export type HotelServerParam = HelperType & {
  image?: string | ArrayBuffer | null;
};

export const HotelForm = ({ title }: { title?: string }) => {
  const saveFormAndHandleErrors = useSaveFormAndHandleErrors("hotels");
  const handleHotelSave = async (data: Hotel, event: any) => {
    const { image } = data;
    const transformedHotel = { ...data } as HotelServerParam;
    if (image && image.rawFile) {
      transformedHotel.image = await convertFileToBase64(image);
    } else {
      transformedHotel.image = undefined;
    }

    return saveFormAndHandleErrors({ hotel: { ...transformedHotel } }, event);
  };

  return (
    <SimpleForm sanitizeEmptyValues onSubmit={handleHotelSave}>
      <Container maxWidth="md">
        <Header label={title} />
        <TextInput source="id" label="ID" disabled />
        <TextInput source="name" fullWidth />
        <TextInput source="phone_number" />
        <Stack direction="row" spacing={2}>
          <TextInput margin="none" source="checkin" />
          <TextInput source="checkout" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextInput margin="none" source="lat" />
          <TextInput source="lng" />
        </Stack>
        <TextInput fullWidth source="street" />
        <Stack direction="row" spacing={2}>
          <TextInput margin="none" source="city" />
          <TextInput source="state" />
          <TextInput source="zip" />
        </Stack>
        <TextInput fullWidth source="synxis_code" />
        <ImageField
          resource="hotels"
          urlSource="image_url"
          fileSource="image"
          label="Image"
        />
      </Container>
    </SimpleForm>
  );
};

export const HotelEdit = () => (
  <Edit headerSource="name">
    <HotelForm />
  </Edit>
);

export const HotelCreate = () => (
  <Create>
    <HotelForm title="Create new hotel" />
  </Create>
);
