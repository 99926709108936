import * as React from "react";

import { Card, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { ArloLogo } from "./ArloLogo";

interface AuthContainerProps {
  children: React.ReactNode;
  title?: string;
}

const sx = {
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  card: {
    minWidth: 300,
    px: 3,
    pt: 2,
    pb: 4,
    mt: "6em",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    alignSelf: "center",
    mb: 3,
    width: "80px",
  },
  title: {
    alignSelf: "center",
    mb: 5,
  },
};

export const AuthContainer = ({ children, title }: AuthContainerProps) => (
  <Box sx={sx.container}>
    <Card sx={sx.card}>
      <Box sx={sx.logo}>
        <ArloLogo />
      </Box>
      {title && (
        <Box sx={sx.title}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      {children}
    </Card>
  </Box>
);
