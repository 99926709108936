import { Chip } from "@mui/material";

// prop type needs to be any because react admin does some magic with props specified
const sx = {
  chip: {
    mb: 1,
  },
};

export const QuickFilter = ({ label }: any) => (
  <Chip sx={sx.chip} label={label} />
);
