import { Menu } from "react-admin";
import { Box } from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HotelIcon from "@mui/icons-material/Hotel";
import HomeIcon from "@mui/icons-material/Home";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import Notifications from "@mui/icons-material/Notifications";
import DescriptionIcon from "@mui/icons-material/Description";
import StarIcon from "@mui/icons-material/Star";

import { ArloLogo } from "./ArloLogo";

const sx = {
  logo: {
    mt: "20px",
    mb: "30px",
    ml: "16px",
    width: "80px",
    alignSelf: "center",
    ".RaMenu-closed &": {
      display: "none",
    },
  },
};

export const CustomMenu = () => (
  <Menu>
    <Box sx={sx.logo}>
      <ArloLogo />
    </Box>
    <Menu.Item to="/credits" primaryText="Points" leftIcon={<StarIcon />} />
    <Menu.Item to="/users" primaryText="Users" leftIcon={<GroupIcon />} />
    <Menu.Item
      to="/waitlist"
      primaryText="Waitlist"
      leftIcon={<GroupAddIcon />}
    />
    <Menu.Item
      to="/reservations"
      primaryText="Reservations"
      leftIcon={<HotelIcon />}
    />
    <Menu.Item to="/hotels" primaryText="Properties" leftIcon={<HomeIcon />} />
    <Menu.Item
      to="/notifications"
      primaryText="Notifications"
      leftIcon={<Notifications />}
    />
    <Menu.Item
      to="/points_windows"
      primaryText="Promotions"
      leftIcon={<LoyaltyIcon />}
    />
    <Menu.Item to="/pages" primaryText="Pages" leftIcon={<DescriptionIcon />} />
  </Menu>
);
