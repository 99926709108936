import {
  Datagrid,
  DateField,
  NumberField,
  List,
  SimpleShowLayout,
  TextField,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SearchInput,
} from "react-admin";
import { Container, Stack } from "@mui/material";

import { Show } from "../components/Show";
import { Header } from "../components/Header";

import { PointsWindow } from "../types";
import { Create } from "../components/Create";
import { Edit } from "../components/Edit";
import { useSaveFormAndHandleErrors } from "../hooks/useSaveFormAndHandleErrors";

const filters = [<SearchInput source="keywords" alwaysOn />];
export const PointsWindowsList = () => (
  <List filters={filters} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="beginning" />
      <DateField source="ending" />
      <NumberField source="multiplier" sortable={false} />
    </Datagrid>
  </List>
);

export const PointsWindowShow = () => (
  <Show
    renderHeader={(record: PointsWindow) => (
      <Header label={`${record?.name}` || ""} />
    )}
  >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="beginning" />
      <DateField source="ending" />
      <NumberField source="multiplier" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

const PointsWindowForm = ({ title }: { title?: string }) => {
  const saveFormAndHandleErrors = useSaveFormAndHandleErrors("points_windows");
  const handleSave = async (data: PointsWindow, event: any) => {
    saveFormAndHandleErrors({ points_window: data }, event);
  };

  const parseDate = (dateString: string) => {
    const dateObject = new Date(dateString);
    if (!dateObject) {
      return "";
    }
    return `${dateObject.getMonth() + 1}/${
      dateObject.getDate() + 1
    }/${dateObject.getFullYear()}`;
  };

  return (
    <SimpleForm onSubmit={handleSave}>
      <Container maxWidth="md">
        <Header label={title} />
        <TextInput source="id" disabled />
        <TextInput source="name" fullWidth />
        <NumberInput source="multiplier" fullWidth />
        <Stack direction="row" spacing={2}>
          <DateInput parse={parseDate} margin="none" source="beginning" />
          <DateInput parse={parseDate} source="ending" />
        </Stack>
      </Container>
    </SimpleForm>
  );
};

export const PointsWindowEdit = () => (
  <Edit
    renderHeader={(record: PointsWindow) => (
      <Header label={`${record?.name}` || ""} />
    )}
  >
    <PointsWindowForm />
  </Edit>
);

export const PointsWindowCreate = () => (
  <Create>
    <PointsWindowForm title="Create new promotion" />
  </Create>
);
