import { useCallback } from "react";
import { useCreatePath, useSaveContext, useTranslateLabel } from "react-admin";

export const useSaveFormAndHandleErrors = (resource: string) => {
  const translateLabel = useTranslateLabel();
  const saveContext = useSaveContext();
  const createPath = useCreatePath();

  const saveFormAndHandleErrors = useCallback(
    async (data: any, event: any, redirect = true) => {
      const errors = await saveContext?.save?.(data, event);
      if (!!errors && Object.keys(errors)?.length > 0) {
        const errorObject = {} as Record<string, string>;
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            const label = translateLabel({ source: key });
            errorObject[key] = `${label} ${errors[key]}`;
          }
        }
        return errorObject;
      }
      if (redirect) {
        window.location.href = createPath({
          resource,
          type: "list",
        });
      }
      return true;
    },
    [translateLabel, saveContext, resource, createPath],
  );

  return saveFormAndHandleErrors;
};
