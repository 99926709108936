import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { Form, required, TextInput, useNotify } from "react-admin";
import Box from "@mui/material/Box";

import { sendResendPin, sendVerifyPIN } from "../api/authentication";
import { AuthContainer } from "../components/AuthContainer";

const sx = {
  input: {
    mb: 1,
  },
  button: {
    mt: 2,
    mb: 2,
  },
  subtitle: {
    mb: 2,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  resendButton: {
    color: "#1976d2",
    "&:hover": { cursor: "pointer" },
  },
};

export const VerifyCodePage = () => {
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);

  const notify = useNotify();
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    setLoading(true);

    try {
      const email = sessionStorage.getItem("email");
      if (!email) {
        notify(
          "No email found in session. Go back to forgot password page and enter email.",
          { type: "error" }
        );
        setDisableButton(true);
        return;
      }

      const response = await sendVerifyPIN({ user: { ...data, email } });
      const { token } = response?.data || {};
      sessionStorage.setItem("token", token);
      navigate("/reset-password");
    } catch (error: any) {
      setLoading(false);
      notify(typeof error === "string" ? error : error.message, {
        type: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResend = () => {
    try {
      const email = sessionStorage.getItem("email");
      if (!email) {
        notify(
          "No email found in session. Go back to forgot password page and enter email.",
          { type: "error" }
        );
        setDisableButton(true);
        return;
      }

      sendResendPin({ user: { email } });
      notify("verification email sent.");
    } catch (error: any) {
      setLoading(false);
      notify(typeof error === "string" ? error : error.message, {
        type: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!sessionStorage.getItem("email")) {
      notify(
        "No email found in session. Go back to forgot password page and enter email.",
        { type: "error" }
      );
      setDisableButton(true);
    }
  }, [notify]);

  return (
    <Box>
      <Form onSubmit={handleSubmit}>
        <AuthContainer title="VerifyCode">
          <Typography sx={sx.subtitle}>
            Enter verification code sent in email
          </Typography>
          <Box sx={sx.input}>
            <TextInput
              autoFocus
              source="verification_pin"
              label="Code"
              disabled={loading}
              validate={required()}
              fullWidth
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading || disableButton}
              fullWidth
              sx={sx.button}
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              Verify Code
            </Button>
            <Box sx={sx.actions}>
              <Link href="/forgot-password">
                <Typography>Back</Typography>
              </Link>
              <Box onClick={handleResend}>
                <Typography variant="button" sx={sx.resendButton}>
                  Resend Code
                </Typography>
              </Box>
            </Box>
          </Box>
        </AuthContainer>
      </Form>
    </Box>
  );
};
