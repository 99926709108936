import { Box, Stack, Typography } from "@mui/material";
import { useInput } from "react-admin";

const DEFAULT_LIST = [
  "#C6B3BC", // Purple
  "#C5A98B", // Brown
  "#D1A68C", // Beige
  "#D2CFC0", // Tan
  "#E9C8A8", // Peach
];

interface ColorInputProps {
  source: string;
  colors?: string[];
}

export const ColorInput = ({ source, colors }: ColorInputProps) => {
  const { field } = useInput({ source });
  const { value, onChange } = field || {};

  const colorList = colors || DEFAULT_LIST;

  const selectColor = (color: string) => {
    onChange(color);
  };

  const sx = {
    container: {
      width: "100px",
      height: "100px",
      background: value,
      mb: "10px",
    },
    color: {
      width: "20px",
      height: "20px",
      "&:hover": { cursor: "pointer", border: "2px solid black" },
    },
  };

  return (
    <>
      <Typography variant="body2">{`Color ${value || ""}`}</Typography>
      <Box sx={sx.container} />
      <Stack direction="row" spacing="5px">
        {colorList.map((color) => (
          <Box
            key={color}
            sx={{
              ...sx.color,
              background: color,
            }}
            onClick={() => selectColor(color)}
          />
        ))}
      </Stack>
    </>
  );
};
