import {
  useRecordContext,
  useDataProvider,
  ImageField as RAImageField,
  ImageInput,
  useNotify,
} from "react-admin";
import { Button, Typography } from "@mui/material";

function formatImageSource(value: unknown) {
  if (!value || typeof value === "string") {
    return { url: value };
  }
  return value;
}

interface ImageFieldProps {
  label?: string;
  urlSource: string;
  fileSource: string;
  resource: string;
}

export const ImageField = ({
  label,
  urlSource,
  fileSource,
  resource,
}: ImageFieldProps) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const onDelete = async () => {
    try {
      if (record?.id) {
        await dataProvider.deletePhoto(resource, record?.id, fileSource);
        window.location.reload();
      }
    } catch (error) {
      notify(error as string, { type: "error" });
    }
  };
  return (
    <>
      <Typography>{label}</Typography>
      {record?.[urlSource] ? (
        <>
          <RAImageField source={urlSource} />
          <Button onClick={onDelete} variant="outlined">
            Remove Photo
          </Button>
        </>
      ) : (
        <ImageInput
          format={formatImageSource}
          label={false}
          source={fileSource}
        >
          <RAImageField source="src" />
        </ImageInput>
      )}
    </>
  );
};
