import { useRecordContext } from "react-admin";
import { Typography } from "@mui/material";

interface HeaderProps {
  source?: string;
  label?: string;
  sx?: any;
}

const headerSx = {
  header: {
    m: "10px",
    mt: "25px",
  },
};

export const Header = ({ source, label, sx }: HeaderProps) => {
  const record = useRecordContext();
  const text = source ? record?.[source] : label;
  return (
    <Typography variant="h4" sx={{ ...headerSx.header, ...sx }}>
      {text}
    </Typography>
  );
};
