import { Typography } from "@mui/material";

interface DollarFieldProps {
  label?: string;
  amount?: number;
}

export const DollarField = ({ label, amount }: DollarFieldProps) => {
  if (!amount && amount !== 0) {
    return null;
  }

  return (
    <Typography
      variant="body2"
      component="span"
    >{`$${amount?.toLocaleString()}`}</Typography>
  );
};
